var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("Top Sound Alerts Bits List")]),_c('table',{staticClass:"table table-dark table-striped mt-4"},[_vm._m(0),_c('tbody',_vm._l((_vm.channels),function(channel,index){return _c('tr',{key:index},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(index + 1))]),(_vm.twitchUserData[channel.channel_id])?_c('td',[_c('a',{attrs:{"href":'http://twitch.tv/' + channel.loginname,"target":"_blank","title":_vm.twitchUserData[channel.channel_id].description}},[(_vm.twitchUserData[channel.channel_id])?_c('img',{staticStyle:{"height":"26px"},attrs:{"src":_vm.twitchUserData[channel.channel_id]
                                    .profile_image_url}}):_vm._e(),(channel.displayName)?_c('span',[_vm._v(" "+_vm._s(channel.displayName))]):_vm._e()]),(
                            _vm.twitchUserData[channel.channel_id]
                                .broadcaster_type === 'partner'
                        )?_c('img',{staticClass:"ms-2",attrs:{"src":require("@/assets/partner.png"),"height":"18"}}):_vm._e()]):_c('td',[_c('a',{attrs:{"href":'http://twitch.tv/' + channel.loginname,"target":"_blank"}},[_vm._v(" "+_vm._s(channel.displayName))])]),_c('td',{staticClass:"text-end"},[(_vm.twitchUserData[channel.channel_id])?_c('span',[_vm._v(_vm._s(_vm.knumberformat( _vm.twitchUserData[channel.channel_id].view_count )))]):_vm._e()]),_c('td',[(channel.lastalert_timestamp)?_c('span',{staticClass:"text-success",class:{
                            'text-danger':
                                Date.now() - channel.lastalert_timestamp >
                                6.048e8,
                        }},[_c('timeago',{attrs:{"datetime":channel.lastalert_timestamp}})],1):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.getDate(channel.created_at.seconds * 1000)))]),_c('td',[_vm._v(_vm._s(_vm.knumberformat(channel.totalplayed)))]),_c('td',[_vm._v(" "+_vm._s(Math.round( (channel.totalbits / channel.totalplayed) * 100 ) / 100)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getBitsPerDay( channel.totalbits, channel.created_at.seconds ))+" ")]),_c('td',[(channel.totalbits)?_c('span',[_vm._v(_vm._s(_vm.knumberformat(channel.totalbits)))]):_vm._e()])])}),0)]),_c('div',{staticClass:"text-center mt-3 mb-4"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.loadMore()}}},[_vm._v("Load more")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Channel")]),_c('th',{staticClass:"text-end",attrs:{"scope":"col"}},[_vm._v("Channel views")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Last Alert")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Installed")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Total Played")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Average Bits")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Bits per day")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Total Bits")])])])}]

export { render, staticRenderFns }