<template>
    <div id="app">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-4">
            <div class="container">
                <a class="navbar-brand" href="#">Soun Alerts Insights</a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link
                                :to="{name: 'UsersTop'}"
                                class="nav-link"
                                :class="{active: $route.name == 'UsersTop'}"
                                href="#"
                                >Top Streamers</router-link
                            >
                        </li>
                        <li class="nav-item">
                            <router-link
                                :to="{name: 'UsersRecent'}"
                                class="nav-link"
                                :class="{active: $route.name == 'UsersRecent'}"
                                href="#"
                                >Recent Streamers</router-link
                            >
                        </li>
                        <li class="nav-item" v-if="userClaims.admin">
                            <router-link
                                :to="{name: 'Admin'}"
                                class="nav-link"
                                :class="{active: $route.name == 'Admin'}"
                                href="#"
                                >Admin</router-link
                            >
                        </li>
                    </ul>
                    <form class="d-flex">
                        <div class="" v-if="auth">
                            <button
                                class="btn btn-outline-danger"
                                @click="logout">
                                Logout
                            </button>
                        </div>
                        <div class="" v-else>
                            <a
                                :href="
                                    'https://dashboard.soundalerts.com/auth?redirectUrl=' +
                                    currentlDomain +
                                    '&forceauth=true&target=soundalerts'
                                "
                                class="btn btn-success"
                                >Login</a
                            >
                        </div>
                    </form>
                </div>
            </div>
        </nav>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script></script>
<script>
    export default {
        name: "App",
        data() {
            return {
                userClaims: {},
            }
        },
        created() {
            let self = this
            if (this.$route.query.satoken) {
                this.$firebase
                    .auth()
                    .signInWithCustomToken(this.$route.query.satoken)
                    .then(function () {
                        console.log("suceess")
                    })
                    .catch(function (error) {
                        console.error(error)
                    })
                let query = Object.assign({}, this.$route.query)
                delete query.satoken
                this.$router.replace({query})
            }

            this.$firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    user.getIdTokenResult(true).then((idTokenResult) => {
                        this.userClaims = idTokenResult.claims
                        this.$store.commit(
                            "setUserClaims",
                            idTokenResult.claims
                        )
                        this.$store.commit("setIdToken", idTokenResult.token)
                    })
                    // User is signed in.
                    console.log("signed in")
                    self.$store.commit("authenticate", user)
                } else {
                    // No user is signed in.
                    console.log("signed out")
                }
            })
        },
        computed: {
            auth() {
                return this.$store.state.auth
            },
            currentlDomain() {
                if (!window) return ""
                return window.location.origin
            },
        },

        methods: {
            logout() {
                let self = this
                this.$firebase
                    .auth()
                    .signOut()
                    .then(function () {
                        // Sign-out successful.
                        self.$store.commit("authenticate", null)
                    })
                    .catch(function (error) {
                        // An error happened.
                    })
            },
        },
    }
</script>

<style>
    #app,
    html,
    body {
        background: #101010;
        color: white;
    }

    a {
        text-decoration: none;
        color: #4fbaff;
    }
</style>
