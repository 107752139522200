<template>
    <div>
        <div class="card">
            <div class="card-body text-dark">
                <h2>Add distributed counter for sound doc</h2>
                <p>
                    Mark a high traffic sound document to shard sound play
                    counter
                </p>
                <form @submit.prevent="submitSoundSharding">
                    <div class="mb-3 row">
                        <label for="soundid" class="col-sm-2 col-form-label"
                            >Sound ID</label
                        >
                        <div class="col-sm-10">
                            <input
                                type="text"
                                class="form-control"
                                id="soundid"
                                placeholder="123"
                                v-model="sound_id" />
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label
                            for="sound_shards"
                            class="col-sm-2 col-form-label"
                            >Shards</label
                        >
                        <div class="col-sm-10">
                            <input
                                type="number"
                                class="form-control"
                                id="sound_shards"
                                min="2"
                                max="5"
                                v-model="num_shards" />
                        </div>
                    </div>
                    <button class="btn btn-primary mt-2" type="submit">
                        Submit
                    </button>
                </form>
                <button
                    class="btn btn-primary mt-4"
                    @click="fetchShardedSounds">
                    Refresh
                </button>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Shards</th>
                            <th scope="col">Counter</th>
                            <th scope="col">Last Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(sound, index) in sharded_sounds"
                            :key="index">
                            <td>{{ sound.name }}</td>
                            <td>{{ sound.num_shards }}</td>
                            <td>{{ sound.played }}</td>
                            <td>
                                <timeago
                                    :datetime="
                                        sound.last_update.toDate()
                                    "></timeago>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex"

    export default {
        created() {
            this.fetchShardedSounds()
        },
        data() {
            return {
                sound_id: "",
                num_shards: 3,
                sharded_sounds: [],
            }
        },
        computed: {
            ...mapState(["idtoken"]),
        },
        methods: {
            submitSoundSharding() {
                this.$axios({
                    method: "post",
                    url:
                        process.env.VUE_APP_FUNCTIONS_URL +
                        "/sharding-markHighTraffic",
                    params: {
                        sound_id: this.sound_id,
                        num_shards: this.num_shards,
                    },
                    headers: {
                        "x-idtoken": this.idtoken,
                    },
                })
                    .then(() => {
                        this.$toast.success("You did it!")
                    })
                    .catch((error) => {
                        console.error(error)
                        this.$toast.error("Error")
                    })
            },
            fetchShardedSounds() {
                this.sharded_sounds = []
                this.$db
                    .collection("sharded_sounds")
                    .orderBy("last_update", "asc")
                    .limit(50)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            this.sharded_sounds.push(doc.data())
                        })
                    })
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
