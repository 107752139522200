import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        auth: null,
        idtoken: "",
        userClaims: {},
    },
    mutations: {
        authenticate(state, payload) {
            state.auth = payload
        },
        setUserClaims(state, payload) {
            state.userClaims = payload
        },
        setIdToken(state, payload) {
            state.idtoken = payload
        },
    },
})
