<template>
    <div>
        <h3>Recent Installs</h3>
        <table class="table table-dark table-striped mt-4">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">#</th>

                    <th scope="col">Channel</th>
                    <th scope="col">Activated</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(channel, index) in channels" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                        <a :href="'http://twitch.tv/' + channel.loginname"
                            ><span v-if="channel.displayName">{{
                                channel.displayName
                            }}</span></a
                        >
                    </td>
                    <td>
                        <timeago
                            :datetime="
                                channel.created_at.seconds * 1000
                            "></timeago>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        created() {
            let self = this
            this.$db
                .collection("users")
                .limit(50)
                .orderBy("created_at", "desc")
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        self.channels.push(doc.data())
                    })
                })
        },
        data() {
            return {
                addchannel_response: "",
                addchannel_name: "",
                channels: [],
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth
            },
        },
        methods: {},
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
