import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

Vue.config.productionTip = false
import Vuex from "vuex"
Vue.use(Vuex)

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

import VueToast from "vue-toast-notification"
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css"

Vue.use(VueToast)

Vue.prototype.$axios = require("axios")
Vue.prototype.$_ = require("lodash")
Vue.prototype.$firebase = firebase

const config = {
    apiKey: process.env.VUE_APP_FB_API_KEY,
    authDomain: "twitch-soundboard.firebaseapp.com",
    databaseURL: "https://twitch-soundboard.firebaseio.com",
    projectId: "twitch-soundboard",
    storageBucket: "twitch-soundboard.appspot.com",
    messagingSenderId: "678173988783",
}

// Initialize Firebase

const firebaseApp = firebase.initializeApp(config)
const db = firebaseApp.firestore()
Vue.prototype.$db = db

import VueTimeago from "vue-timeago"

Vue.use(VueTimeago, {
    name: "Timeago", // Component name, `Timeago` by default
    locale: undefined, // Default locale
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app")
