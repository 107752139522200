import Vue from "vue"
import VueRouter from "vue-router"
import Start from "@/components/Start"
import UsersRecent from "@/components/UsersRecent"
import UsersTop from "@/components/UsersTop"
import Admin from "@/components/Admin"
import store from "../store"
Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "Start",
        component: Start,
    },
    // {
    //   path: '/channels/track',
    //   name: 'UsersTrack',
    //   component: UsersTrack,
    //   beforeEnter: (to, from, next) => {
    //     if (store.state.auth==null) {
    //       next('/')
    //     }else{
    //       next()
    //     }
    //   }
    // },
    // {
    //   path: '/channels/live',
    //   name: 'UsersLive',
    //   component: UsersLive,
    //   beforeEnter: (to, from, next) => {
    //     if (store.state.auth==null) {
    //       next('/')
    //     }else{
    //       next()
    //     }
    //   }
    // },
    {
        path: "/channels/recent",
        name: "UsersRecent",
        component: UsersRecent,
        beforeEnter: (to, from, next) => {
            if (store.state.idtoken == null) {
                next("/")
            } else {
                next()
            }
        },
    },
    {
        path: "/channels/top",
        name: "UsersTop",
        component: UsersTop,
        beforeEnter: (to, from, next) => {
            if (store.state.idtoken == null) {
                next("/")
            } else {
                next()
            }
        },
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        beforeEnter: (to, from, next) => {
            if (store.state.idtoken == null) {
                next("/")
            } else {
                next()
            }
        },
    },
]

const router = new VueRouter({
    mode: "history",
    routes,
})

export default router
