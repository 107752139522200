<template>
    <div>
        <h3>Top Sound Alerts Bits List</h3>
        <table class="table table-dark table-striped mt-4">
            <thead class="">
                <tr>
                    <th scope="col">#</th>

                    <th scope="col">Channel</th>
                    <th class="text-end" scope="col">Channel views</th>
                    <th scope="col">Last Alert</th>
                    <th scope="col">Installed</th>
                    <th scope="col">Total Played</th>
                    <th scope="col">Average Bits</th>
                    <th scope="col">Bits per day</th>
                    <th scope="col">Total Bits</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(channel, index) in channels" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td v-if="twitchUserData[channel.channel_id]">
                        <a
                            :href="'http://twitch.tv/' + channel.loginname"
                            target="_blank"
                            :title="
                                twitchUserData[channel.channel_id].description
                            "
                            ><img
                                :src="
                                    twitchUserData[channel.channel_id]
                                        .profile_image_url
                                "
                                style="height: 26px"
                                v-if="twitchUserData[channel.channel_id]" />
                            <span v-if="channel.displayName">
                                {{ channel.displayName }}</span
                            ></a
                        >
                        <img
                            src="@/assets/partner.png"
                            v-if="
                                twitchUserData[channel.channel_id]
                                    .broadcaster_type === 'partner'
                            "
                            class="ms-2"
                            height="18" />
                    </td>
                    <td v-else>
                        <a
                            :href="'http://twitch.tv/' + channel.loginname"
                            target="_blank">
                            {{ channel.displayName }}</a
                        >
                    </td>
                    <td class="text-end">
                        <span v-if="twitchUserData[channel.channel_id]">{{
                            knumberformat(
                                twitchUserData[channel.channel_id].view_count
                            )
                        }}</span>
                    </td>

                    <td>
                        <span
                            v-if="channel.lastalert_timestamp"
                            class="text-success"
                            :class="{
                                'text-danger':
                                    Date.now() - channel.lastalert_timestamp >
                                    6.048e8,
                            }"
                            ><timeago
                                :datetime="
                                    channel.lastalert_timestamp
                                "></timeago
                        ></span>
                    </td>
                    <td>{{ getDate(channel.created_at.seconds * 1000) }}</td>
                    <td>{{ knumberformat(channel.totalplayed) }}</td>
                    <td>
                        {{
                            Math.round(
                                (channel.totalbits / channel.totalplayed) * 100
                            ) / 100
                        }}
                    </td>
                    <td>
                        {{
                            getBitsPerDay(
                                channel.totalbits,
                                channel.created_at.seconds
                            )
                        }}
                    </td>
                    <td>
                        <span v-if="channel.totalbits">{{
                            knumberformat(channel.totalbits)
                        }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-center mt-3 mb-4">
            <a class="btn btn-primary" href="#" @click.prevent="loadMore()"
                >Load more</a
            >
        </div>
    </div>
</template>

<script>
    export default {
        name: "TopList",
        created() {
            this.$db
                .collection("users")
                .limit(this.limit)
                .orderBy("totalbits", "desc")
                .get()
                .then((querySnapshot) => {
                    this.lastVisible =
                        querySnapshot.docs[querySnapshot.docs.length - 1]
                    let channelIdParams = new URLSearchParams()
                    querySnapshot.forEach((doc) => {
                        this.channels.push(doc.data())
                        channelIdParams.append("id", doc.data().channel_id)
                    })
                    this.fetchTwitchUserData(channelIdParams)
                })
        },
        data() {
            return {
                addchannel_response: "",
                addchannel_name: "",
                channels: [],
                cursor: 50,
                limit: 50,
                lastVisible: null,
                twitchUserData: {},
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth
            },
        },
        methods: {
            getDays(seconds) {
                return (Date.now() / 1000 - seconds) / 86400
            },
            getBitsPerDay(bits, seconds) {
                return (bits / this.getDays(seconds)).toFixed(1)
            },
            getDate(timestamp) {
                const date = new Date(timestamp)
                return (
                    date.getFullYear() +
                    "/" +
                    (date.getMonth() + 1) +
                    "/" +
                    date.getDate()
                )
            },
            knumberformat(number) {
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            loadMore() {
                this.$db
                    .collection("users")
                    .orderBy("totalbits", "desc")
                    .startAfter(this.lastVisible)
                    .limit(50)
                    .get()
                    .then((querySnapshot) => {
                        this.lastVisible =
                            querySnapshot.docs[querySnapshot.docs.length - 1]
                        let channelIdParams = new URLSearchParams()
                        querySnapshot.forEach((doc) => {
                            this.channels.push(doc.data())
                            channelIdParams.append("id", doc.data().channel_id)
                        })

                        this.fetchTwitchUserData(channelIdParams)
                    })
            },
            fetchTwitchUserData(channelIdParams) {
                this.$axios
                    .get(
                        process.env.VUE_APP_FUNCTIONS_URL +
                            "/api-getTwitchUserData?" +
                            channelIdParams.toString()
                    )
                    .then((response) => {
                        this.twitchUserData = {
                            ...this.twitchUserData,
                            ...this.$_.keyBy(response.data, "id"),
                        }
                    })
            },
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
