<template>
    <div>
        <div v-if="auth">Welcome</div>
        <div v-else>You are not logged in</div>
    </div>
</template>

<script>
    export default {
        components: {},
        created() {
            // let uid = this.auth.uid
            // console.log(uid)
            // let self = this
            // db.collection("users_analytics").doc(uid).collection('channels').get().then(function(querySnapshot) {
            //     querySnapshot.forEach(function(doc) {
            //         // doc.data() is never undefined for query doc snapshots
            //         // console.log(doc.id, " => ", doc.data());
            //         self.channels.push(doc.data())
            //     });
            // });
        },
        data() {
            return {
                addchannel_name: "altoar",
                channels: [],
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth
            },
        },
        methods: {},
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
